body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Menu CSS */
.main-menu {
  background: black;
  border-bottom: none;
}

.main-menu-item {
  color: white;
  font-size: 30px;
}

.ant-menu-item {
  border-bottom: none !important;
}

.ant-menu-submenu {
  border-bottom: none !important;
}

.logo-item {
  font-size: 40px;
  color: white !important;
  border-bottom: none !important;
}

.main-menu-item-clickable {
  float: right;
}

.main-menu-item-clickable:hover {
  color: black !important;
  background: white !important;
  border-bottom: none !important;
}

.ant-menu-item-selected {
  color: black !important;
  background: white;
  border-bottom: none !important;
  font-size: 35px;
}

/* Fonts config CSS */

@font-face {
  font-family: "bebas_neue";
  src: url(/static/media/BebasNeue-Regular.47f711bd.ttf);
}

.bebas_neue {
  font-family: "bebas_neue";
}

@font-face {
  font-family: "montserrat";
  src: url(/static/media/Montserrat-Medium.d815b0a2.otf);
}

.montserrat {
  font-family: "montserrat";
}

@font-face {
  font-family: "oldstandardtt";
  src: url(/static/media/OldStandard-Regular.5d36040c.ttf);
}

.oldstandardtt {
  font-family: "oldstandardtt";
}

/* Experiences Section CSS */

.timeline_date {
  font-size: 1.5em;
}

.timeline_title {
  font-size: 3em;
}

.timeline_subtitle {
  font-size: 1.78em;
}

.timeline_description {
  font-size: 1.3em;
}

.text_title {
  font-size: 9vw;
  color: black;
  line-height: 7vw;
  text-align: right;
  margin: 0
}

.text_subtitle {
  text-align: right;
  color: black;
  margin: 0
}

/* Home CSS */
.home_title_complement_bottom {
  font-size: 1.7vw;
  margin-top: -3.7vw;
  text-align: right;
  margin-bottom: 0;
}

.home_title_complement_top {
  font-size: 1.7vw;
  margin-bottom: -3.1vw;
}

.home_title {
  font-size: 9vw;
  margin: 0;
  text-align: center;
}

/* Admin CSS */

.ant-card-extra > a {
  color: grey;
  font-size: 20px;
}

.ant-tabs-nav-wrap {
  color: grey;
  font-size: 20px;
  font-family: "oldstandardtt";
}

.ant-tabs-tab-active {
  color: black !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: black;
}

.ant-tabs-ink-bar {
  background-color: black;
}

/* Responsive CSS */
/* Smartphones (landscape) ----------- */
@media only screen and (max-width: 768px) {
  /* Home CSS*/
  .home_title_complement_bottom {
    font-size: 5vw;
    margin-top: -8.6vw;
    text-align: right;
    margin-bottom: 0;
  }

  .home_title_complement_top {
    font-size: 5vw;
    margin-bottom: -7vw;
  }

  .home_title {
    font-size: 20vw;
    margin: 0;
    text-align: center;
  }

  /* Experiences Section CSS */
  .text_title {
    text-align: left;
    font-size: 16vw;
    line-height: 13vw;
  }

  .text_subtitle {
    text-align: left;
    color: black;
    margin-bottom: 50px
  }

  .hide_on_cp {
    display: none;
  }
}

